import React from "react";
import Link from "./link";
import "./arrow-link.scss";

export default ({ children, ...props }) => (
  <div className="arrow-link-container">
    <Link {...props} className="arrow-link">
      <span>{children}</span>
    </Link>
  </div>
);
