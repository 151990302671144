import React from "react";
import ArrowLink from "./arrow-link";

import "./text-imagegrid.scss";

export const Text = ({
  headline,
  link,
  isWide,
  children,
  linkBeforeChildren,
  centerVertically
}) => {
  const arrowLink = link ? (
    <ArrowLink url={link.url} type={link.type}>
      {link.label}
    </ArrowLink>
  ) : (
    false
  );
  return (
    <div
      className={`text ${isWide ? "wide" : ""} ${
        centerVertically ? "center-vertically" : ""
      }`}
    >
      <div className="text-container">
        {headline ? (
          <>
            <h2
              className="headline"
              dangerouslySetInnerHTML={{ __html: headline }}
            />
            {linkBeforeChildren ? arrowLink : ""}
            <div className="desc">{children}</div>
          </>
        ) : (
          children
        )}

        {!linkBeforeChildren && arrowLink}
      </div>
    </div>
  );
};

export const ImageGrid = ({ images, isWide, hideMobile }) => {
  let grid = null;

  if (images.length === 3) {
    grid = (
      <>
        <div className="col">
          <div className="imagegrid-image">
            {(Array.isArray(images[0]) &&
              images[0].map(image => (
                <img
                  src={image.image}
                  className={image.active ? "active" : "not-active"}
                />
              ))) || <img src={images[0]} />}
          </div>
          <div className="imagegrid-image">
            <img src={images[1]} />
          </div>
        </div>
        <div className="col">
          <div className="imagegrid-image">
            <img src={images[2]} />
          </div>
        </div>
      </>
    );
  } else {
    grid = images.map((image, i) => (
      <div className="imagegrid-image" key={i}>
        <img src={image} />
      </div>
    ));
  }
  return (
    <div
      className={`imagegrid imagegrid-${images.length} ${
        isWide ? "wide" : ""
      } ${hideMobile ? "hide-mobile" : ""}`}
    >
      {grid}
    </div>
  );
};

export default ({
  images,
  headline,
  link,
  backgroundDark,
  children,
  split,
  reverse,
  wideImage,
  smallColumn,
  linkBeforeChildren,
  hideImageMobile,
  centerVertically,
  ...props
}) => (
  <section
    className={`text-imagegrid ${props.className || ""} ${
      backgroundDark ? "background-dark" : ""
    } ${reverse ? "reverse" : ""} ${wideImage ? "wide-image" : ""} ${
      smallColumn ? "small-column" : ""
    }`}
  >
    <Text
      headline={headline}
      link={link}
      isWide={reverse}
      linkBeforeChildren={linkBeforeChildren}
      centerVertically={centerVertically}
    >
      {children}
    </Text>
    <ImageGrid
      images={images}
      isWide={wideImage}
      hideMobile={hideImageMobile}
    />
  </section>
);
