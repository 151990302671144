import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import TextImageGrid from "../components/text-imagegrid";

import introImage from "../images/neu/frau_hut.jpg";

const ImprintPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <Intro image={introImage} headline="Impressum">
      <p>
        Informationspflicht laut §5 E-Commerce Gesetz, §14
        Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut
        §25 Mediengesetz
      </p>
      <p>
        Bürozeiten: Mo-Fr von 9 bis 14 Uhr
        <br />
        Telefon: +43 1 266 0 266 0<br />
        E-Mail: &nbsp;
        <a
          href="mailto:office@omellis.at"
          target="_blank"
          rel="noreferrer noopener"
        >
          office@omellis.at
        </a>
      </p>
    </Intro>
    <TextImageGrid
      headline="o’mellis GmbH"
      reverse={true}
      smallColumn={true}
      images={[
        require("../images/neu/fruechte.jpg"),
        require("../images/neu/mann_obst.jpg")
      ]}
    >
      <p>Eitnergasse 13, 1230 Wien</p>
      <p>Firmenbuchnummer: 84490a</p>
      <p>Firmenbuchgericht: Handelsgericht Wien</p>
      <p>
        UID-Nr.: ATU14411402
        <br />
      </p>
      <p>
        Behörde gem. ECG (E-Commerce Gesetz)Magistratisches Bezirksamt des I.
        BezirkesMagistratisches Bezirksamt des X. BezirkesMagistratisches
        Bezirksamt des XIV. BezirkesMagistratisches Bezirksamt des XV.
        BezirkesMagistratisches Bezirksamt des XXII. Bezirkes
      </p>
      <p>
        Mitglied bei: Wirtschaftskammer Wien / FG Gastronomie Buffets aller Art
        (einschließlich Tankstellenbuffets) / LG Lebensmittelhandel Einzelhandel
        mit Lebensmitteln
        <u>
          <br />
        </u>
        <br />
      </p>
      <p>
        <a
          href="http://ris.bka.gv.at/"
          target="_blank"
          rel="noreferrer noopener"
        >
          http://ris.bka.gv.at
        </a>
      </p>
      <p>
        <strong>Fotos:</strong> o’mellis, AdobeStock, Shutterstock, Stocksy,
        Tamás Künsztler
      </p>
    </TextImageGrid>
  </Layout>
);

export default ImprintPage;
